<template>
  <div class="login balckmask" @mousemove.prevent>
    <div class="box">
      <div class="flex_row_right">
        <img @click="close" class="close" src="@/static/common/close.png" alt="">
      </div>
      <div class="title">登录方式</div>
      <div class="flex_row_center">
        <div class="sort" @click="getUrl">
          <img class="icon" src="@/static/common/wx.png" alt="">
          <div>微信登录</div>
        </div>
        <div class="sort" @click="showQQ">
          <img class="icon" src="@/static/common/qq.png" alt="">
          <div>QQ登录</div>
        </div>
      </div>
    </div>

    <verify v-if="isShowverify" @close="close" @changeUser="changeUser"></verify>

  </div>
</template>

<script>
import {
  checkMobile, setToken, setLoginStatus, setUserInfo, setAccount, getAccount, removeAccount
} from "@/utils/auth";
import verify from '@/components/verify/verify.vue'

export default {
  name: "web-login",
  components: { verify },
  data() {
    return {
      isShowverify: false,
    };
  },
  computed: {},
  mounted() {
    // **********************假数据**************************
    // setToken('1b00021ec9a1e7fdbf6581dc22093aa8');
    // setLoginStatus("true");
    // this.$store.commit("CHANGELOGINSTATUS", true);
    // this.get(this.Api.userInfo, {}).then((res) => {
    //   setUserInfo(res);
    //   this.$store.commit("UPDATAINFO", res);
    //   if (res.has_mobile == false) {
    //     this.isShowverify = true
    //   }
    // });
    // **********************假数据**************************
  },

  methods: {

    changeUser() {
      this.$emit('changeUser');
    },
    // close() {
    //   this.isShowverify = false
    // },
    getUrl() {
      localStorage.setItem('loginType', 'WX');
      this.axios.post(this.Api.getScanCode, {
        url: 'https://www.yicaiic.com/home'
        // url: 'http://www.yicaiic.com/api/login/scanLogin'
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          window.location.href = res.data.url; //url就是微信的第三方页面了})
        }
      });
    },
    showQQ() {
      localStorage.setItem('loginType', 'QQ');
      this.axios.post(this.Api.getqqScanLogin, {
        url: 'https://www.yicaiic.com/home'
        // url: 'http://www.yicaiic.com/api/login/scanLogin'
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          window.location.href = res.data.url; //url就是微信的第三方页面了})
        }
      });
      // let oOpts = {
      //   appId: "102079314",
      //   redirectURI: "https://www.yicaiic.com/home"
      // }
      // QC.Login.showPopup(oOpts)
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
}

.box {
  width: 576px;
  height: 455px;
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(68, 120, 246, 0.15);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  padding: 22px;

  .close {
    width: 22px;
    height: 22px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .title {
    font-size: 32px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    text-align: center;
    margin-bottom: 67px;
  }

  .sort {
    width: 158px;
    height: 190px;
    cursor: pointer;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    margin: 0 30px;
    padding: 36px 38px;

    .icon {
      width: 82px;
      height: 82px;
      margin-bottom: 12px;
    }

    div {
      height: 24px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: center;
    }
  }

  .sort:hover {
    background: rgba(88, 134, 247, 0.05);
  }
}
</style>
