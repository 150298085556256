<template>
  <div class="component-upload-image">
    <el-upload multiple :disabled="disabled" :action="uploadImgUrl" list-type="picture-card" :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload" :limit="limit" :on-error="handleUploadError" :on-exceed="handleExceed" name="file" :on-remove="handleRemove"
      :show-file-list="true" :headers="headers" :file-list="fileList" :on-preview="handlePictureCardPreview"
      :class="{ hide: this.fileList.length >= this.limit }">
      <i class="el-icon-plus"></i>
      <div class="desc">{{ desc }}</div>
    </el-upload>

    <!-- 上传提示 -->
    <div class="el-upload__tip" slot="tip" v-if="showTip">
      <!-- 请上传
      <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
      <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template>
      的文件 -->
      （最多可上传3张）
    </div>

    <el-dialog :visible.sync="dialogVisible" title="预览" width="800" append-to-body>
      <img :src="dialogImageUrl" style="display: block; max-width: 100%; margin: 0 auto" />
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import { Message, MessageBox, Notification, Loading } from 'element-ui'
let loadingInstance;
export default {
  props: {
    value: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 5,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    // 是否可以编辑
    disabled: {
      type: Boolean,
      default: false
    },
    desc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      number: 0,
      uploadList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      uploadImgUrl: this.$HTTP + "upload/image", // 上传的图片服务器地址
      // uploadImgUrl: "https://api.pping.vip/api/user/upload", // 上传的图片服务器地址

      headers: {
        'token': getToken(),
      },
      fileList: []
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.value.split(',');
          // 然后将数组转为对象数组
          this.fileList = list.map(item => {
            if (typeof item === "string") {
              item = { name: item, url: item };
            }
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
    // 删除图片
    handleRemove(file, fileList) {
      const findex = this.fileList.map(f => f.name).indexOf(file.name);
      if (findex > -1) {
        this.fileList.splice(findex, 1);
        this.$emit("input", this.listToString(this.fileList));
      }
    },
    // 上传成功回调
    handleUploadSuccess(res) {
      console.log(res)
      if (res.code == 1 && res.data != null) {
        // res.data.forEach(data => {
        this.uploadList.push({ name: res.data.name, url: res.data.uri });
        console.log(1)
        // })
      }
      if (this.uploadList.length === this.number) {
        console.log(2)
        this.fileList = this.fileList.concat(this.uploadList);
        this.uploadList = [];
        this.number = 0;
        this.$emit("input", this.listToString(this.fileList));
        // this.$modal.closeLoading();
        this.loadingInstance.close();
      }
    },
    // 上传前loading加载
    handleBeforeUpload(file) {
      console.log(this.fileType, 7751)
      let isImg = false;
      if (this.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = this.fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        Message.error(`文件格式不正确, 请上传${this.fileType.join("/")}图片格式文件!`)
        // this.$modal.msgError();
        return false;
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          Message.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`)
          // this.$modal.msgError();
          return false;
        }
      }

      this.loadingInstance = Loading.service({
        lock: true,
        text: '正在上传图片，请稍候...',
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
      // this.$modal.loading("正在上传图片，请稍候...");
      this.number++;
    },
    // 文件个数超出
    handleExceed() {
      Message.error(`上传文件数量不能超过 ${this.limit} 个!`)
      // this.$modal.msgError();
    },
    // 上传失败
    handleUploadError() {
      Message.error("上传图片失败，请重试")
      // this.$modal.msgError();
      this.loadingInstance.close();
      // this.$modal.closeLoading();
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        strs += list[i].url + separator;
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    }
  }
};
</script>
<style scoped>
/* // .el-upload--picture-card 控制加号部分 */
::v-deep.hide .el-upload--picture-card {
  display: none;
}

::v-deep .el-upload--picture-card {
  position: relative;
  width: 88px;
  height: 88px;
  line-height: 88px;
}

/* // 去掉动画效果 */
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
  transition: all 0s;
}

::v-deep .el-list-enter,
.el-list-leave-active {
  opacity: 0;
  transform: translateY(0);
}

.desc {
  height: 20px;
  font-size: 12px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 60px;
  z-index: 9;
  /* left: 50%;
  transform: translate(-50%, 0px); */
  text-align: center;
  left: 0;
  right: 0;
}
</style>

