<template>
  <div class="report balckmask" @mousemove.prevent>
    <div class="box">
      <div class="flex_row_right">
        <img @click="close" class="close" src="@/static/common/close.png" alt="">
      </div>
      <div class="title">举报</div>
      <div class="step">①请填写举报信息</div>
      <div class="item flex_row_left" style="align-items:flex-start">
        <div class="label">上传截图：</div>
        <!-- <ImageUpload :desc="'上传截图'"></ImageUpload> -->
        <image-upload v-model="ruleForm.image" :limit="1" :isShowTip="false" :desc="'上传截图'" />
      </div>
      <div class="item flex_row_left" style="align-items:flex-start">
        <div class="label">描述内容：</div>
        <el-input type="textarea" style="width: 388px;" :rows="7" placeholder="请描述您的举报内容详情" v-model="ruleForm.content">
        </el-input>
      </div>

      <div class="step">②请输入举报人的联系方式</div>
      <div class="item flex_row_left">
        <div class="label">我的电话：</div>
        <el-input style="width: 222px;" placeholder="请输入联系方式" v-model="ruleForm.mobile">
        </el-input>
      </div>
      <div class="item flex_row_left">
        <div class="label">我的qq：</div>
        <el-input style="width: 222px;" placeholder="请输入联系方式" v-model="ruleForm.qq">
        </el-input>
      </div>
      <div class="flex_row_center">
        <div class="btn" @click="subMit()">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpload from '@/components/ImageUpload/index.vue'
export default {
  name: "web-report",
  components: { ImageUpload },
  props: {
    reportId: {
      type: Number,
      default: () => 0
    },
  },
  data() {
    return {
      ruleForm: {
        image: '',
        content: '',
        mobile: '',
        qq: '',
        demand_id: 0
      },
      info: {}
    };
  },
  computed: {},
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.get(this.Api.userInfo, {}).then((res) => {
        this.info = res;
        this.ruleForm.mobile = this.info.mobile
        this.ruleForm.qq = this.info.qq
      });
    },
    subMit() {
      if (!this.ruleForm.image) {
        this.$message.error('请上传截图');
        return
      }
      if (!this.ruleForm.content) {
        this.$message.error('请填写举报内容');
        return
      }
      if (!this.ruleForm.mobile) {
        this.$message.error('请填写手机号');
        return
      }
      if (!this.ruleForm.qq) {
        this.$message.error('请填写QQ');
        return
      }
      this.ruleForm.demand_id = this.reportId
      this.post(this.Api.demandreport, this.ruleForm).then(res => {
        if (res.code == 1) {
          this.$message({
            message: '提交举报成功！',
            type: 'success'
          });
          this.close()
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
}
::v-deep .el-upload--picture-card {
  width: 88px;
  height: 88px;
  line-height: 88px;
}

::v-deep .el-upload--picture-card i {
  font-size: 10px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 88px;
  height: 88px;
  line-height: 40px;
  // margin: 0;
  // border-radius: 50%;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-actions {
  font-size: 12px;
}

::v-deep
  .el-upload-list--picture-card
  .el-upload-list__item-actions
  span
  + span {
  margin-left: 8px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label {
  position: absolute;
  right: -17px;
  top: -15px;
  width: 40px;
  height: 30px;
  background: #13ce66;
  text-align: center;
  transform: rotate(45deg);
  box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
}
.box {
  width: 548px;
  max-height: 663px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  padding: 10px 42px;
  position: relative;
  padding-bottom: 38px;

  .close {
    width: 22px;
    height: 22px;
    cursor: pointer;
    position: absolute;
    right: 22px;
    top: 22px;
  }

  .title {
    height: 50px;
    height: 27px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #303030;
    line-height: 50px;
    text-align: center;
    margin-bottom: 16px;
  }

  .step {
    height: 20px;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #5886f7;
    line-height: 20px;
    margin: 16px 0;
  }

  .item {
    margin-bottom: 16px;
  }

  .label {
    width: 70px;
    height: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    white-space: nowrap;
    margin-right: 6px;
  }

  .btn {
    width: 130px;
    height: 31px;
    background: #5886f7;
    border-radius: 25px 25px 25px 25px;
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    margin-top: 32px;
    cursor: pointer;
  }
}
</style>
