let ApiRoot = 'https://game.04nl.com/api/'; //红墨笔
let Api = {
	getScanCode: 'login/getScanCode',//获取请求授权url
	getqqScanLogin: 'login/qqScanLogin',//获取请求授权url
	scanLogin: 'login/scanLogin',//扫码登录code
	qqscanLogin: 'login/qqScanCallback',//qq扫码登录code
	logOut: 'login/logout',//退出登录
	userInfo: 'user/info',//个人信息
	adlists: 'advert/lists',//广告位
	demandlists: 'demand/lists',//需求列表
	demandadd: 'demand/add',//发布
	demanddetail: 'demand/detail',//发布详情
	demandquotation: 'demand/quotation',//报价
	demandreport: 'demand/report',//举报
	terminalDetail: 'user/terminalDetail',//终端认证详情
	userterminal: 'user/terminal',//终端认证
	changeUser: 'user/changeUser',//修改用户信息
	myQuotation: 'demand/myQuotation',//我的报价列表
	mydemand: 'demand/mydemand',//我的需求列表
	deletemydemand: 'demand/delete',//删除我的需求列表
	showQuotation: 'demand/showQuotation',//查看报价
	viplists: 'vip/lists',//vip列表
	policy: 'index/policy',//政策
	customer: 'index/customer',//客服
	config: 'pc/config',//网站配置
	sendCode: 'sms/sendCode',//发送短信
	bindMobile: 'user/bindMobile',//绑定手机号
	placeOrder: 'vip/placeOrder',//生成订单
	prepay: 'pay/prepay',//预支付
	payStatus: 'pay/payStatus',//支付状态
	getFollowList: 'user/getFollowList',//我的关注
	addFollow: 'user/addFollow',//添加我的关注
	deleteFollow: 'user/deleteFollow',//删除
	recommLists: 'demand/recommLists',//list关注
	bindUserLeader: 'user/bindUserLeader',//绑定
	notice: 'index/notice',//公告
}
export default Api
