<template>
  <div class="customer balckmask" @mousemove.prevent>
    <div class="box">
      <div class="flex_row_right">
        <img @click="close" class="close" src="@/static/common/close.png" alt="">
      </div>
      <div class="title">联系客服</div>
      <img class="qrcode" :src="qrcodeurl" alt="">
      <!-- <div class="qrcode" ref="qrCodeUrl"></div> -->
      <div class="tip">扫一扫添加客服微信</div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: "customer",
  components: {},
  props: {
    id: {
      type: Number,
      default: () => 0
    },
  },
  data() {
    return {
      qrcodeurl: ''
    };
  },
  computed: {},
  mounted() {
    this.getcustomer()
    // this.creatQrCode()
  },
  methods: {
    getcustomer() {
      this.post(this.Api.customer, {}).then(res => {
        if (res.code == 1) {
          this.qrcodeurl = res.data.content
          // this.creatQrCode(res.data.content)
        }
      })
    },
    creatQrCode(url) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 178,
        height: 178,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
}

.box {
  padding: 22px 87px;
  position: relative;
  padding-bottom: 48px;
  width: 352px;
  height: 361px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  .close {
    width: 22px;
    height: 22px;
    cursor: pointer;
    position: absolute;
    right: 22px;
    top: 22px;
  }
  .title {
    height: 50px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #303030;
    line-height: 50px;
    text-align: center;
    margin-bottom: 36px;
  }
  .qrcode {
    width: 178px;
    height: 178px;
    margin-right: 24px;
  }
  .tip {
    height: 18px;
    font-size: 12px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
