import Cookies from 'js-cookie'

const TokenKey = 'PC-Token'

const ExpiresInKey = 'Admin-Expires-In'

const LoginStatus = 'isLogin'

const UserInfo = 'UserInfo'

const Account = 'Account'

export function setAccount(info) {
  return Cookies.set(Account, JSON.stringify(info))
}

export function getAccount() {
  return Cookies.get(Account)
}

export function removeAccount() {
  return Cookies.remove(Account)
}

export function setUserInfo(info) {
  return Cookies.set(UserInfo, JSON.stringify(info))
}

export function getUserInfo() {
  return JSON.parse(Cookies.get(UserInfo))
}

export function removeUserInfo() {
  return Cookies.remove(UserInfo)
}

export function setLoginStatus(status) {
  return Cookies.set(LoginStatus, status)
}

export function getLoginStatus() {
  return Cookies.get(LoginStatus)
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey)
}
export function checkMobile(value) {
  let reg = /^1\d{10}$/
  if (value == '') {
    return false;
  } else if (!reg.test(value)) {
    return false;
  }
  return true;
}