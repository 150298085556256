//1.引入vue
import Vue from "vue"
//2.引入vue-router
import VueRouter from "vue-router";
import home from '@/views/home/home.vue'
//3.注册vue-router
Vue.use(VueRouter);
//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//4.路径
const routes = [
  {
    path: "/",
    redirect: '/home',
    name: "home",
    component: home,
    meta: {
      title: '首页',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  },
  {
    path: "/home",
    name: "home",
    component: home,
    meta: {
      title: '易采',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
    meta: {
      title: '登录',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  },
  {
    path: "/quotation",
    name: "quotation",
    component: () => import("@/views/home/quotation.vue"),
    meta: {
      title: '报价',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  },
  {
    path: "/publish",
    name: "publish",
    component: () => import("@/views/home/publish.vue"),
    meta: {
      title: '发布',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  },
  {
    path: "/my",
    name: "my",
    component: () => import("@/views/my/my.vue"),
    meta: {
      title: '个人中心',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  },
  {
    path: "/business",
    name: "business",
    component: () => import("@/views/my/business.vue"),
    meta: {
      title: '认证终端',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  },
  {
    path: "/order",
    name: "order",
    component: () => import("@/views/my/order.vue"),
    meta: {
      title: '订单管理',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  },
  {
    path: "/seequotation",
    name: "seequotation",
    component: () => import("@/views/my/seequotation.vue"),
    meta: {
      title: '查看报价',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  }, {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/agreement/agreement.vue"),
    meta: {
      title: '个人中心',
      content: {
        keywords: '易采',
        description: '易采-采购助手'
      }
    }
  }
  // {
  //   path: "/find.html",
  //   name: "find",
  //   component: () => import("@/views/find/index.vue"),
  //   children: [
  //     {
  //       path: "home",
  //       name: "home",
  //       component: () => import("@/views/home/home.vue"),
  //     },
  //   ],
  //   meta: {
  //     title: '易采',
  //     content: {
  //       keywords: '易采',
  //       description: '易采-采购助手'
  //     }
  //   }
  // },
];
//4.创建
const router = new VueRouter({
  mode: 'history',//模式
  base: "/",//根路径
  routes,
});
//5.共享
export default router;
