import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store/index';
import Cookies from 'js-cookie'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router';
import Header from '@/components/header/header.vue'
import Footer from '@/components/footer/footer.vue'
import gobal from '@/utils/global.js'
// import { setToken } from '@/utils/auth'
import { scrollTo } from '@/utils/scroll-to'
import Api from '@/utils/index.js'
import defalutlogo from '@/assets/logo.png'

Vue.use(gobal)
Vue.use(VueAxios, axios)
Vue.use(ElementUI);
Vue.use(Cookies)
Vue.config.productionTip = false
Vue.component('p-Header', Header) // 全局头部
Vue.component('p-Footer', Footer) // 全局底部
Vue.prototype.Api = Api
Vue.prototype.$store = store
Vue.prototype.dateFormat = function (origiVal) {
  const dt = new Date(origiVal * 1000)
  // const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  // const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${m}/${d} ${hh}:${mm}`
}
Vue.prototype.scrollTo = scrollTo
Vue.prototype.$bus = new Vue()
// 正式版
// Vue.prototype.$HTTP = 'http://yicai.04nl.com/api/';
// axios.defaults.baseURL = 'http://yicai.04nl.com/api/';

// 客户的服务器测试登陆用
Vue.prototype.$HTTP = 'https://www.yicaiic.com/api/';
axios.defaults.baseURL = 'https://www.yicaiic.com/api/';


Vue.prototype.imgUrl = 'http://game.04nl.com/';
Vue.prototype.logoimg = defalutlogo;
router.beforeEach((to, from, next) => {
  //    路由发生变化改变description和keyword
  const device = navigator.userAgent
  // if (device.indexOf('iPad') > -1) {
  //   //  ipad 
  // } else if (device.indexOf('Android') > -1 || device.indexOf('ios') > -1) {
  //   // 手机
  //   window.location.href = 'https://h5.pping.vip/h5/'
  //   return
  // } else {
  //   // 电脑
  // }
  // if (to.meta.content) {
  //   const head = document.getElementsByTagName('head')
  //   const meta = document.createElement('meta')
  //   document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
  //   document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
  //   meta.content = to.meta.content
  //   head[0].appendChild(meta)
  // }
  // 路由发生变化修改页面title
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  next()
})
router.afterEach((to, from) => {
  // console.log(from)
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
