<template>
  <div class="web-vip balckmask" @mousemove.prevent>
    <div class="box">
      <div class="flex_row_right">
        <img @click="close" class="close" src="@/static/common/close.png" alt="">
      </div>
      <div class="title">续费会员</div>
      <!-- 
      <div class="flex_row_left" style="flex-wrap: wrap;">
        <div v-for="(e,i) in viplist" :key="i">
          <div class="price pos_rel" :class="{'on':e.id==selectItem.id}" @click="changeVip(e)">
            <div class="recomend">{{e.orange}}</div>
            <div class="price-name">{{e.name}}</div>
            <div class="price-num"><span>￥</span>{{e.price}}</div>
          </div>
          <div class="viptip">
            {{ e.content }}
          </div>
        </div>
      </div> -->

      <div class="pay flex_row_left">
        <div class="qrcode" ref="qrCodeUrl" style="width: 130px;height: 130px;"></div>
        <div class="flex1">
          <div class="flex_row_left" style="margin-bottom:10px">
            <img class="wx" src="@/static/common/wx.png" alt="">
            <div class="pay-tip">微信扫码，续费支付</div>
            <!-- <div class="pay-price"><span>￥</span>{{selectItem.price}}</div> -->
          </div>
          <div class="flex_row_left">
            <!-- <el-checkbox v-model="checked" size="medium"></el-checkbox> -->
            <!-- <div class="pay-rule">开通会员即代表同意<span @click="toAgree(1)">《会员服务协议》</span>和<span @click="toAgree(2)">《隐私政策》</span></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Cookies from 'js-cookie'
import QRCode from 'qrcodejs2'
export default {
  name: "web-vip",
  components: {},
  props: {
    id: {
      type: Number,
      default: () => 0
    },
  },
  data() {
    return {
      checked: false,
      viplist: [],
      selectItem: {},
      from: {},
      qrcode: null
    };
  },
  computed: {
    vip_id: {
      get() {
        return this.$store.state.vip_id;
      },
      set(val) { },
    },
  },
  mounted() {
    this.createOrder()
    this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
      width: 130,
      height: 130,
      colorDark: '#000000',
      colorLight: '#ffffff',
    })
  },
  updated() {

  },
  destroyed() {
    localStorage.setItem('isPay', 0)
  },
  //     onUpdated(() => {
  //   console.log('更新')
  //   let isPay = localStorage.getItem('isPay')
  //   if (isPay == 'true') {
  //     handleCheckIsPay()
  //   }
  // })
  // onUnmounted(() => {
  //   localStorage.setItem('isPay', 'false')
  //   console.log('销毁')
  // })
  methods: {
    toAgree(id) {
      let routeData = this.$router.resolve({
        path: "/agreement?id=" + id,
      });
      window.open(routeData.href, "_blank");
    },
    changeVip(e) {
      this.qrcode.clear(); // 清除代码
      this.selectItem = e
      this.wechatPayCode = ''
      setTimeout(() => {
        this.createOrder()
      }, 1000);
    },
    getViplist() {
      this.post(this.Api.viplists, {}).then(res => {
        this.viplist = res.data
        this.selectItem = res.data[0]
        setTimeout(() => {
          this.createOrder()
        }, 1000);
      })
    },
    createOrder() {
      let data = {
        id: this.vip_id,
        terminal: 4,
        user_id: JSON.parse(Cookies.get('UserInfo')).id
      }
      this.post(this.Api.placeOrder, data).then(res => {
        this.from = res.data
        localStorage.setItem('orderType', res.data.from)
        localStorage.setItem('orderNo', res.data.order_id)
        this.prePay()
      })
    },
    prePay() {
      let data = {
        from: this.from.from,
        pay_way: 2,
        order_id: this.from.order_id,
        redirect: '/home'
      }
      this.post(this.Api.prepay, data).then(res => {
        this.qrcode.clear()
        this.wechatPayCode = res.data.config
        localStorage.setItem('isPay', 1)
        this.creatQrCode()
      })
    },
    handleCheckIsPay() {
      // this.$message({
      //   type: "success",
      //   message: "支付结果查询中...",
      // });
      let data = {
        from: localStorage.getItem('orderType'),
        order_id: localStorage.getItem('orderNo'),
        pay_way: 2,
      }
      let timer = setInterval(() => {
        this.post(this.Api.payStatus, data).then((res) => {
          if (res.data.pay_status == 1) {
            clearInterval(timer)
            this.$message({
              type: "success",
              message: "支付成功",
            });
            this.$emit('close');
          }

        })
      }, 3000)
    },
    creatQrCode() {
      this.qrcode.makeCode(this.wechatPayCode)
      console.log('更新')
      this.handleCheckIsPay()
      // let isPay = localStorage.getItem('isPay')
      // console.log(isPay)
      // if (isPay == 1) {
      //   this.handleCheckIsPay()
      // }
    },
    close() {
      localStorage.setItem('isPay', 0)
      this.$emit('close');
    }
  },
};
</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
}

.box {
  min-width: 480px;
  min-height: 288px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  padding: 10px 42px;
  position: relative;
  padding-bottom: 42px;

  .close {
    width: 22px;
    height: 22px;
    cursor: pointer;
    position: absolute;
    right: 22px;
    top: 22px;
  }

  .title {
    height: 50px;
    height: 27px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #303030;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px;
  }

  .price {
    width: 150px;
    height: 147px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #d3d6db;
    margin-right: 12px;
    padding: 32px 0;
    cursor: pointer;

    .recomend {
      background: url("@/static/common/label.png") no-repeat;
      background-size: 100% 100%;
      width: 50px;
      height: 19px;
      font-size: 11px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 19px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-name {
      height: 24px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #232323;
      line-height: 24px;
      text-align: center;
      margin-bottom: 14px;
    }

    &-num {
      height: 45px;
      font-size: 30px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #f2724d;
      line-height: 45px;
      text-align: center;

      span {
        font-size: 16px;
      }
    }
  }

  .on {
    border: 1px solid #5886f7;
  }

  .viptip {
    width: 150px;
    height: 72px;
    font-size: 12px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
    margin: 16px 0 28px 0;
    padding: 0 10px;
  }

  .pay {
    width: 390px;
    height: 178px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #d3d6db;
    padding: 24px;

    &-tip {
      height: 21px;
      font-size: 14px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 21px;
      margin-right: 6px;
    }

    &-price {
      height: 45px;
      font-size: 30px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #f2724d;
      line-height: 35px;

      span {
        font-size: 16px;
      }
    }

    &-rule {
      margin-left: 4px;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 23px;
      cursor: pointer;

      span {
        color: #378eff;
      }
    }

    .qrcode {
      width: 130px;
      height: 130px;
      margin-right: 24px;
    }

    .wx {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
  }
}

.qrcode img {
  width: 130px;
  height: 130px;
}
</style>