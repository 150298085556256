<template>
  <div class="verify balckmask" @mousemove.prevent>
    <div class="box">
      <div class="flex_row_right">
        <img @click="close" class="close" src="@/static/common/close.png" alt="">
      </div>
      <div class="title flex_row_center">
        <img class="check" src="@/static/common/check.png" alt="">
        登录方式
      </div>
      <div class="tip">请完善您的登录信息</div>
      <div class="flex_row_center">
        <el-input style="width: 414px;height: 52px;border-radius: 8px 8px 8px 8px;margin-bottom: 18px;" v-model="ruleForm.mobile" placeholder="请输入手机号"
          type="text" autocomplete="off">
          <template #prefix>
            <img class="icon" src="@/static/common/tel.png" />
          </template>
        </el-input>
      </div>
      <div class="flex_row_center">
        <el-input style="width: 414px;height: 52px;border-radius: 8px 8px 8px 8px;margin-bottom: 18px;" v-model="ruleForm.code" placeholder="请输入动态密码"
          type="text" autocomplete="off">
          <template #prefix>
            <img class="icon" src="@/static/common/pas.png" />
          </template>
          <template #append>
            <div class="yzm" @click="getCode">{{ timeCount == 0 ? '发送动态密码' : timeCount + 's' }}</div>
          </template>
        </el-input>
      </div>
      <div class="flex_row_center">
        <el-input style="width: 414px;height: 52px;border-radius: 8px 8px 8px 8px;margin-bottom: 18px;" v-model="ruleForm.company_name" placeholder="请公司名称"
          type="text" autocomplete="off">
          <template #prefix>
            <img class="icon" src="@/static/common/gs.png" />
          </template>
        </el-input>
      </div>
      <div class="flex_row_center">
        <div class="btn" @click="bindMobile()">同意协议并绑定</div>
      </div>
      <div class="flex_row_center">
        <div class="agree">阅读并接受<span @click="toAgree(2)">《用户协议和隐私政策》</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "web-verify",
  components: {},
  data() {
    return {
      ruleForm: {
        type: 'bind',
        mobile: '',
        company_name: '',
        code: ''
      },
      timeCount: 0,
      timeer: null
    };
  },
  computed: {},
  mounted() {
  },
  methods: {
    toAgree(id) {
      let routeData = this.$router.resolve({
        path: "/agreement?id=" + id,
      });
      window.open(routeData.href, "_blank");
    },
    close() {
      this.$emit('close');
    },
    getCode() {
      let that = this;
      if (this.timeCount > 0) {
        return
      }
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.ruleForm.mobile)) {
        this.$message.error('请填写正确手机号码');
        return false
      }
      this.axios.post(this.Api.sendCode, {
        mobile: this.ruleForm.mobile,
        scene: 'BDSJHM'
      }).then((res) => {
        if (res.code == 1) {
          this.timeCount = 60
          clearInterval(this.timeer)
          this.timeer = setInterval(() => {
            if (this.timeCount > 0) {
              this.timeCount = this.timeCount - 1
            } else {
              clearInterval(that.timeer)
            }
          }, 1000)
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    bindMobile() {
      if (!this.ruleForm.mobile) {
        this.$message.error('请输入手机号');
        return
      }
      if (!this.ruleForm.code) {
        this.$message.error('请输入动态密码');
        return
      }
      if (!this.ruleForm.company_name) {
        this.$message.error('请输入公司名称');
        return
      }
      this.axios.post(this.Api.bindMobile, this.ruleForm).then((res) => {
        if (res.code == 1) {
          this.$emit('changeUser');
          this.$emit('close');
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
}

.box {
  padding: 22px;
  width: 576px;
  height: 538px;
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(68, 120, 246, 0.15);
  border-radius: 12px 12px 12px 12px;

  .close {
    width: 22px;
    height: 22px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .check {
    width: 32px;
    height: 32px;
    margin-right: 14px;
  }

  .title {
    font-size: 32px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    text-align: center;
    margin-bottom: 10px;
  }

  .tip {
    height: 24px;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    text-align: center;
    margin-bottom: 42px;
  }

  ::v-deep .el-input-group--append .el-input__inner,
  ::v-deep .el-input-group__prepend,
  ::v-deep .el-input__inner {
    height: 52px;
  }

  ::v-deep .el-input--prefix .el-input__inner {
    padding-left: 52px;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-top: 16px;
    margin-left: 20px;
  }

  .yzm {
    // height: 52px;
    // background: rgba(31, 33, 40, 0);
    // opacity: 1;
    // font-size: 15px;
    // font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    // font-weight: 400;
    color: #4478f6;
    cursor: pointer;
    // line-height: 52px;
    // text-align: center;
  }

  .btn {
    width: 414px;
    height: 52px;
    background: #4478f6;
    box-shadow: 0px 4px 22px 0px rgba(111, 126, 201, 0.25);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 52px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .agree {
    width: 414px;
    height: 19px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 19px;

    span {
      color: #378eff;
      cursor: pointer;
    }
  }
}
</style>
