<template>
  <div class="home">
    <el-image class="bannerimg" :src="banner" fit="cover" lazy></el-image>
    <div class="flex_row_center MB48">
      <div class="search flex_row_between">
        <el-input v-model="filterParams.keyword" placeholder="请输入搜索内容" />
        <div class="search-btn flex_row_center" @click="toSearch">
          <img src="@/static/home/search.png" alt="" />
          搜索
        </div>
      </div>
      <div class="publishbth" @click="toPublish">我要发布</div>
    </div>

    <div class="main flex_row_between" style="align-items: flex-start;">
      <div class="ad flex_col_left" style="margin-right: 7px;">
        <div class="ad-img" v-for="(e,i) in adList.left" :key="i">
          <el-image @click="toAD(e.url)" v-show="e.show" class="ad-img-img" :src="e.image?e.image:adimg" fit="cover" lazy></el-image>
          <img v-show="e.show" @click="closead(e)" class="ad-imgicon" src="@/static/home/adicon.png" alt="">
        </div>
      </div>
      <div class="main-list">
        <div class="flex_row_between titlepart">
          <div class="flex_row_between">
            <div class="title pos_rel" @click="doneClick(1)">需求广场
              <img src="@/static/home/textbg.png" alt="" v-if="type==1">
            </div>
            <div class="title pos_rel" @click="doneClick(2)">我关注的
              <img src="@/static/home/textbg.png" alt="" v-if="type==2">
            </div>
          </div>
          <!-- <el-checkbox-group>
            <el-checkbox label="只看工厂" size="medium"></el-checkbox>
            <el-checkbox label="只看终端" size="medium"></el-checkbox>
          </el-checkbox-group> -->
          <div class="flex_row_right sxbox">
            <el-checkbox :disabled="!isLogin || !is_vip" v-model="filterParams.is_factory" :true-label="1" :false-label="0" size="medium"
              @change="changeBox"></el-checkbox>
            只看工厂
            <el-checkbox :disabled="!isLogin || !is_vip" v-model="filterParams.is_terminal" :true-label="1" :false-label="0" size="medium"
              @change="changeBox"></el-checkbox>
            只看终端
          </div>

        </div>
        <div class="flex_row_between warn" v-if="type==2">
          <div class="lists">
            <div class="items" @click="itemClick(item)" v-if="noticeList.length>0" v-for="item in noticeList" :key="item.id">
              <div class="itemNmae" :class="tagId==item.id?'activeName':''">{{item.tag}}<span class="line"></span></div>
              <img src="@/static/common/closeItem.png" class="closeItem" alt="" @click="delData(item.id)">
            </div>
          </div>
          <div class="add">
            <div class="search addModal flex_row_between">
              <el-input v-model="tag" placeholder="添加想关注的型号" />
              <div class="search-btn flex_row_center" @click="toAdd">
                添加
              </div>
            </div>
          </div>
        </div>
        <div class="list flex_row_left flex_wrap">
          <div class="item" v-for="(e,i) in list" :key="i">
            <div class="flex_row_between" style="align-items: flex-start;">
              <div class="item-name flex1 overline1">{{e.title?e.title:''}}</div>
              <el-tooltip effect="dark" content="点击举报" placement="top">
                <img @click="report(e.id)" class="item-report" src="@/static/home/report.png" alt="">
              </el-tooltip>
            </div>
            <div style="height: 120px;">
              <div class="flex_row_left">
                <div class="lable">询价数量：</div>
                <div class="content">{{e.num?e.num:'0'}}个</div>
              </div>
              <div class="flex_row_left" style="align-items: flex-start;">
                <div class="lable">询价要求：</div>
                <div class="content overline2">{{e.other?e.other:''}}</div>
              </div>

              <div class="flex_row_left">
                <div class="lable">询价时间：</div>
                <div class="content">{{e.create_time?e.create_time:''}}</div>
              </div>
              <div class="flex_row_left">
                <div class="lable">已报价数：</div>
                <div class="content"><span>{{e.quotation_num?e.quotation_num:'0'}}</span>个</div>
              </div>
            </div>

            <div class="label flex_row_left">
              <div class="zd" v-show="e.is_terminal==1">终端</div>
              <div class="zd cjgc" v-show="e.is_factory==1">超级工厂</div>
              <div class="flex_row_left" v-show="e.is_terminal==0&&e.is_factory==0&&is_vip">
                <div class="lable">手机号：</div>
                <div class="content">{{e.mobile?e.mobile:''}}</div>
              </div>
            </div>

            <div class="flex_row_between">
              <div class="flex1 overline1 compony">{{e.company_name?e.company_name:'--'}}</div>
              <div style="color: #999999;"><i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="btn" @click="toQuo(e.id)">报价</div>
          </div>
        </div>
        <el-empty v-if="!list||list.length==0" description="暂无数据"></el-empty>
        <!-- <el-pagination background layout="prev, pager, next" v-show="total > 0" :total="total"
          :page.sync="filterParams.page_no" :page-size="filterParams.page_size" :limit.sync="filterParams.page_size"
          @pagination="getList">
        </el-pagination> -->
        <el-pagination v-model:currentPage="filterParams.page_no" :page-size="filterParams.page_size" layout=" prev, pager, next" :total="total"
          v-show="total > 0" @current-change="handleSizeChange">
        </el-pagination>
      </div>
      <div class="ad flex_col_left" style="margin-left: 7px;">
        <div class="ad-img" v-for="(e,i) in adList.right" :key="i">
          <el-image @click="toAD(e.url)" v-show="e.show" class="ad-img-img" :src="e.image?e.image:adimg" fit="cover" lazy></el-image>
          <img v-show="e.show" @click="closead(e)" class="ad-imgicon" src="@/static/home/adicon.png" alt="">
        </div>
      </div>
    </div>
    <report :reportId="reportId" v-if="isReport" @close="close"></report>
    <div v-if="isShow" class="noticeCom">
      <div class="noticeComs">
        <div class="titleNotice" @click="closeNotice">
          <span class="nameNotice">公告信息</span>
          <img src="@/static/common/closeItem.png" class="imgNotice" alt="">
        </div>
        <div class="infoNotice">
          <img class="infoNoticeImg" :src="noticeCom.content" alt="">
          <span>{{noticeCom.title}}</span>
        </div>
        <div class="infoNotice">
          <span class="btnNotice" @click="closeNotice">确认</span>
        </div>
      </div>
    </div>
    <verify v-if="isShowverify" @close="close1" @changeUser="changeUser"></verify>
  </div>
</template>

<script>
import {
  checkMobile, setToken, setLoginStatus, setUserInfo, setAccount, getAccount, removeAccount
} from "@/utils/auth";
import banner from '@/static/home/banner.png'
import adimg from '@/static/home/adimg.png'
import report from '@/components/report/report.vue'
import verify from '@/components/verify/verify.vue'
export default {
  name: "web-home",
  components: { report, verify },
  data() {
    return {
      banner,
      adimg,
      word: '',
      list: [],
      checkList: [],
      isReport: false,
      reportId: 0,
      adList: {},
      isShowverify: false,
      type: 1,
      showNotice: true,
      // 查询参数
      filterParams: {
        page_no: 1,
        page_size: 24,
        keyword: '',
        is_terminal: 0,
        is_factory: 0,
        id: ''
      },
      total: 0,
      noticeList: [],
      tag: '',
      tagId: '',
      noticeCom: '',
      isShow: true
    };
  },
  computed: {
    isLogin: {
      get() {
        return this.$store.state.isLogin;
      },
      set(val) { },
    },
    // isShow: {
    //   get() {
    //     console.log(this.$store.state.isShow)
    //     return this.$store.state.isShow;
    //   },
    //   set(val) { },
    // },
    is_vip: {
      get() {
        console.log(this.$store.state)
        return this.$store.state.is_vip;
      },
      set(val) { },
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        if (route.query.code) {
          this.getToken(route.query);
        }
        if (route.query.param1) {
          this.setQQToken(route.query.param1);
        }

        if (route.query.leader_id) {
          localStorage.setItem('leader_id', route.query.leader_id)
        }
        // if (route.query.loginType) {
        //   this.loginType = route.query.loginType;
        //   this.bindPhone = route.query.bindPhone;
        // }
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created: function () {
    scrollTo(0, 1);
    this.getList()
    this.getAd()
    this.getNoticeCom()
    this.isShow = localStorage.getItem('setIsShow') == null ? true : false
  },
  methods: {
    closeNotice() {
      this.isShow = false
      localStorage.setItem('setIsShow', false)
      // this.$store.commit("setIsShow", false);
    },
    changeUser() {
      this.get(this.Api.userInfo, {}).then((res) => {
        setUserInfo(res);
        this.$store.commit("UPDATAINFO", res);
        if (res.has_mobile == false) {
          this.isShowverify = true
        }
      });
    },
    close1() {
      this.isShowverify = false
    },
    doneClick(code) {
      this.type = code
      if (code == 1) {
        scrollTo(0, 1);
        this.getList()
        this.getAd()
      } else {
        this.getnoticeList()
        this.getrecommon()
      }
    },
    getrecommon() {
      this.filterParams.id = this.tagId
      this.get(this.Api.recommLists, this.filterParams).then(res => {
        this.list = res.lists
        this.total = res.count
      })
    },
    getNoticeCom() {
      this.axios.get(this.Api.notice, {}).then((res) => {
        if (res.code == 1) {
          console.log(res)
          this.noticeCom = res.data
        }
      });
    },
    getToken(code) {
      let loginType = localStorage.getItem('loginType')
      let url = ''
      if (loginType && loginType == 'WX') {
        url = this.Api.scanLogin
      } else {
        url = this.Api.qqscanLogin
      }
      this.axios.get(url + '?code=' + code.code + '&state=' + code.state, {}).then((res) => {
        if (res.code == 1) {
          setToken(res.data.token);
          setLoginStatus("true");
          this.$store.commit("CHANGELOGINSTATUS", true);
          this.get(this.Api.userInfo, {}).then((res) => {
            setUserInfo(res);
            this.$store.commit("UPDATAINFO", res);
            if (res.has_mobile == false) {
              this.isShowverify = true
            }
            if (localStorage.getItem('leader_id') != '' || localStorage.getItem('leader_id') != null || localStorage.getItem('leader_id') != undefined || localStorage.getItem('leader_id') != {}) {
              this.getData()
            }
          });
          this.$router.replace('/home')
        }
      });
    },
    setQQToken(token) {
      setToken(token);
      setLoginStatus("true");
      this.$store.commit("CHANGELOGINSTATUS", true);
      this.get(this.Api.userInfo, {}).then((res) => {
        setUserInfo(res);
        this.$store.commit("UPDATAINFO", res);
        if (res.has_mobile == false) {
          this.isShowverify = true
        }
        if (localStorage.getItem('leader_id') != '' || localStorage.getItem('leader_id') != null || localStorage.getItem('leader_id') != undefined || localStorage.getItem('leader_id') != {}) {
          this.getData()
        }
        this.$router.replace('/home')
      });
    },
    getData() {
      this.axios.post(this.Api.bindUserLeader, { leader_id: localStorage.getItem('leader_id') }).then((res) => {
        if (res.code == 1) {
          localStorage.removeItem('leader_id')
        }
      });
    },
    toAdd() {
      if (this.tag != '') {
        this.axios.post(this.Api.addFollow, { tag: this.tag }).then((res) => {
          if (res.code == 1) {
            this.$message.error(res.msg);
            this.getnoticeList()
            this.tag = ''
          }

        });
      } else {
        this.$message.error('请输入关注的型号');
      }
    },
    delData(code) {
      this.axios.post(this.Api.deleteFollow, { id: code }).then((res) => {
        if (res.code == 1) {
          this.getnoticeList()
        }
      });
    },
    itemClick(item) {
      this.tagId = item.id
      this.getrecommon()
    },
    toAD(url) {
      if (url) {
        // window.location.href = url;
        window.open(url, "_blank");
        // window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
      }
    },
    closead(e) {
      e.show = false
    },
    report(id) {
      if (this.isLogin) {
        this.reportId = id;
        this.isReport = true
      } else {
        this.$message.error('请先登录~');
      }
    },
    handleSizeChange(e) {
      this.filterParams.page_no = e
      if (this.type == 1) {
        this.getList()
      } else {
        this.getrecommon()
      }
    },
    getnoticeList() {
      this.axios.post(this.Api.getFollowList, {}).then((res) => {
        if (res.code == 1) {
          this.noticeList = res.data
          if (res.data.length > 0) {
            this.tagId = res.data[0].id
            this.getrecommon()
          }

        }
      });
    },
    getAd() {
      this.axios.post(this.Api.adlists, {}).then((res) => {
        if (res.code == 1) {
          this.adList = res.data
          this.adList.left = res.data.left.map(e => {
            e.show = true
            return e
          })
          this.adList.right = res.data.right.map(e => {
            e.show = true
            return e
          })
          this.adList = JSON.parse(JSON.stringify(this.adList))
        }
      });
    },
    close() {
      this.isReport = false
    },
    toPublish() {
      if (this.isLogin) {
        this.$router.push("/publish");
      } else {
        this.$message.error('请先登录~');
      }
    },
    toQuo(id) {
      if (this.isLogin) {
        this.$router.push("/quotation?id=" + id);
      } else {
        this.$message.error('请先登录~');
      }
    },
    changeBox() {
      this.filterParams.page_no = 1;
      if (this.type == 1) {
        this.getList()
      } else {
        this.getrecommon()
      }
    },
    toSearch() {
      this.filterParams.page_no = 1;
      if (this.type == 1) {
        this.getList()
      } else {
        this.getrecommon()
      }
    },
    getList() {
      this.get(this.Api.demandlists, this.filterParams).then(res => {
        this.list = res.lists
        this.total = res.count
      })
    },
    goPage(url, item) {
      let urls = url + '?id=' + item.id + '&name=' + item.name
      this.$router.push(urls);
    }
  },
};
</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
}

.MB48 {
  margin-bottom: 48px;
}

.home {
  width: 100%;
  min-width: 1000px;

  .bannerimg {
    width: 100%;
    height: 180px;
    margin-bottom: 48px;
  }

  .search {
    width: 700px;
    height: 50px;

    ::v-deep .el-input__inner {
      height: 48px;
      background: #ffffff;
      border-radius: 61px 61px 61px 61px;
      opacity: 1;
      border: 2px solid #5886f7;
      border-right: none;
      padding-right: 60px;
    }

    &-btn {
      width: 108px;
      height: 48px;
      background: #5886f7;
      border-radius: 40px 40px 40px 40px;
      opacity: 1;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 50px;
      margin-left: -50px;
      z-index: 99;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }

  .publishbth {
    width: 112px;
    height: 50px;
    background: #5886f7;
    border-radius: 40px 40px 40px 40px;
    opacity: 1;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    margin-left: 18px;
  }

  .sxbox {
    height: 21px;
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    white-space: nowrap;

    ::v-deep .el-checkbox,
    ::v-deep .el-checkbox:last-of-type {
      margin-right: 8px;
      margin-left: 18px;
    }
  }

  .main {
    min-width: 1000px;

    &-list {
      flex: 1;
      min-height: 100vh;
      max-width: 1000px;

      .titlepart {
        margin-bottom: 28px;
      }

      .title {
        height: 33px;
        font-size: 22px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
        z-index: 2;
        margin-right: 20px;
        cursor: pointer;

        img {
          width: 48px;
          height: 4px;
          position: absolute;
          left: 0;
          bottom: 4px;
          z-index: 0;
        }
      }

      .list {
        .item {
          width: 234px;
          height: 268px;
          // height: 290px;
          background: #ffffff;
          box-shadow: 0px 2px 12px 0px rgba(88, 134, 247, 0.15);
          border-radius: 12px 12px 12px 12px;
          opacity: 1;
          padding: 12px;
          margin-right: 21px;
          margin-bottom: 22px;

          &-name {
            height: 27px;
            font-size: 18px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 27px;
            margin-top: 4px;
            word-break: break-all;
          }

          &-report {
            width: 18px;
            height: 18px;
            margin-left: 10px;
            cursor: pointer;
          }

          .lable {
            width: 70px;
            height: 21px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
            margin: 2px 0;
          }

          .content {
            flex: 1;
            max-height: 42px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            margin: 2px 0;

            span {
              color: #f2724d;
            }
          }

          .label {
            margin: 6px 0;
            height: 18px;

            .zd {
              height: 18px;
              border-radius: 3px 3px 3px 3px;
              opacity: 1;
              padding: 0 6px;
              font-size: 12px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              margin-right: 6px;
              white-space: nowrap;
            }

            .zd {
              border: 1px solid #00c289;
              color: #00c289;
            }

            .cjgc {
              border: 1px solid #f2724d;
              color: #f2724d;
            }
          }

          .compony {
            height: 21px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
          }

          .btn {
            width: 130px;
            height: 31px;
            background: #5886f7;
            border-radius: 25px 25px 25px 25px;
            opacity: 1;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 31px;
            text-align: center;
            margin: 12px auto 0 auto;
            cursor: pointer;
          }
        }

        @media screen and (min-width: 1500px) {
          .item:nth-child(4n) {
            margin-right: 0;
          }
        }

        @media screen and (min-width: 1245px) and (max-width: 1500px) {
          .item {
            width: calc(33% - 14px);
          }

          .item:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media screen and (min-width: 990px) and (max-width: 1245px) {
          .item {
            width: calc(50% - 12px);
          }

          .item:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media screen and (max-width: 990px) {
          .item {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }

  .ad {
    position: sticky;
    top: 20px;
    padding-top: 33px;

    &-imgicon {
      width: 48px;
      height: 16px;
      position: absolute;
      bottom: 4px;
      right: 0;
      cursor: pointer;
    }

    &-img {
      width: 243px;
      height: auto;
      padding: 0;
      max-height: 491px;
      margin-top: 28px;
      position: relative;

      &-img {
        width: 243px;
        max-height: 491px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .el-pagination {
    text-align: center;
    margin-bottom: 98px;
  }
}

.addModal {
  width: 245px !important;
  height: 32px !important;
}

.warn {
  margin-bottom: 28px;
}

.lists {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 80%;
}

.items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.itemNmae {
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.closeItem {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.activeName {
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 500;
  color: #5886f7;
  position: relative;
  text-align: center;
}

.line {
  position: absolute;
  width: 30px;
  height: 1px;
  left: calc((100% - 30px) / 2);
  bottom: 0px;
  background-color: #5886f7;
}

.noticeCom {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.home {
  position: relative;
}

.noticeComs {
  position: fixed;
  top: 40%;
  z-index: 999;
  left: 45%;
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid red;
}

.titleNotice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.imgNotice {
  width: 18px;
  height: 18px;
}

.nameNotice {
  font-size: 18px;
}

.infoNotice {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.infoNoticeImg {
  width: 180px;
  height: 180px;
}

.infoNotices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.btnNotice {
  padding: 5px 12px;
  background-color: #5886f7;
  font-size: 14px;
  border-radius: 100px;
  color: #fff;
  margin-top: 20px;
}
</style>