<template>
  <div class="webFooter">
    <div class="footview">
      <div class="flex_row_between agree">
        <div @click="toAgree(1)">用户协议</div>
        <div @click="toAgree(2)">隐私协议</div>
        <div @click="toAgree(3)">服务协议</div>
      </div>
      <div class="flex_row_center copyright" v-for="(e,i) in info" :key="i">
        {{ e.key?e.key:` ` }}
        <img v-if="i==0" class="baimg" src="@/static/common/ba.png" alt="">
      </div>
      <!-- ©2022 All Rights Reserved. XX公司 版权所有 | 地址：北京市朝阳区xxx路288号C座 | 电话：15924365858 | <span style="cursor: pointer;"@click="toOther()"> 京公网安备 11010802040098号</span> -->

      <!-- <div class="flex_row_center copyright">网络文化经营许可证：京网文[2023]1234-5678</div>
      <div class="flex_row_center copyright">违法不良信息举报电话：（010）12345678 | 北京市互联网举报中心 | 网上有害信息举报专区</div> -->
      <!-- <div class="flex_row_between copyright">
        <div>{{info.filing_year?info.filing_year:'@2023 惠州市医学研究所·版权所有'}}</div>
        <div>{{info.filing_number?info.filing_number:'粤ICP备2023008997号'}}</div>
        <div style="cursor: pointer;" @click="toAgree()">用户协议免责声明</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "pFooter",
  components: {},
  data() {
    return {
      info: {}
    };
  },
  props: {
    showus: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getaboutUs()
  },
  methods: {
    getaboutUs() {
      this.get(this.Api.config).then(res => {
        this.info = res.copyright
      })
    },
    toAgree(id) {
      let routeData = this.$router.resolve({
        path: "/agreement?id=" + id,
      });
      window.open(routeData.href, "_blank");
    },
    toOther() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.webFooter {
  width: 100%;
  min-width: 1000px;
  height: 181px;
  background: #161d25;
  border-radius: 0px 0px 0px 0px;
}
.footview {
  width: 1000px;
  padding: 32px 26px;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  .agree {
    height: 21px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    margin-bottom: 28px;
    div {
      cursor: pointer;
    }
  }
  .copyright {
    height: 20px;
    font-size: 13px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-bottom: 4px;
  }
  .baimg {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
}
</style>
