<template>
  <div>
    <div class="webHeader" :class="{ 'bgwhite': !isHome }">
      <div class="flex_row_between centeerpart">
        <div class="logopart flex_row_left" @click="toHome()">
          <!-- <div class="navtitle">易采-采购助手</div> -->
          <img class="navtitleimg" src="@/static/common/name.png" alt="">
        </div>
        <div class="flex_row_center opratepart">
          <div v-if="!isLogin" class="btn bluebtn" @click="showLogin()">登录</div>
          <div class="vip" :class="{'is_vip':is_vip}" v-if="isLogin"><span>{{vipname}}</span></div>
          <el-dropdown v-if="isLogin">
            <div class="flex_row_center el-dropdown-link">
              <!-- <div class="username" :class="{'blackusername':!isHome}">{{nickname?nickname:''}}</div> -->
              <el-badge is-dot class="badge" v-if="isRead > 0">
                <el-avatar :size="40" :src="avatar ? avatar : logoimg"></el-avatar>
              </el-badge>
              <el-avatar v-else :size="40" :src="avatar ? avatar : logoimg"></el-avatar>
              <!-- <img class="downicon" src="@/static/common/downicon.png" alt="" /> -->
            </div>
            <el-dropdown-menu slot="dropdown">
              <div class="viptime flex_row_left">
                <img src="@/static/common/vipicon.png" alt="">
                <div v-if="is_vip" class="flex1">{{end_time}}到期</div>
                <div class="flex1 flex_row_left" style="cursor: pointer;" v-else @click="isShowVip=true">
                  <div class="flex1">
                    <div class="viptime-name">升级VIP</div>
                    <div class="viptime-desc">尊享无限报价</div>
                  </div>
                  <div>去开通</div>
                  <img class="vipright" src="@/static/common/vipright.png" alt="">
                </div>
                <div class="flex1 flex_row_left" style="cursor: pointer;" v-if="is_vip" @click="isShowRenewvip=true">
                  <div class="flex1">
                  </div>
                  <div>去续费</div>
                  <img class="vipright" src="@/static/common/vipright.png" alt="">
                </div>
              </div>
              <el-dropdown-item>
                <div class="flex_row_left" @click="toMy('/my')">
                  个人中心
                </div>
              </el-dropdown-item><el-dropdown-item>
                <div class="flex_row_left" @click="toMy('/business')">
                  认证终端
                </div>
              </el-dropdown-item><el-dropdown-item>
                <div class="flex_row_left" @click="toMy('/order')">
                  订单管理
                </div>
              </el-dropdown-item><el-dropdown-item>
                <div class="flex_row_left" @click="isShowCustomer=true">
                  联系客服
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="flex_row_left" @click="loginOut()">
                  退出登录
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- <div style="height: 72px; width: 100%; min-width: 1100px"></div> -->

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" append-to-body>
      <span>确定退出登录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureout()">确 定</el-button>
      </span>
    </el-dialog>
    <transition name="el-fade-in">
      <login v-if="isShowLogin" @close="close" @changeUser="changeUser"></login>
      <verify v-if="isShowverify" @close="close" @changeUser="changeUser"></verify>
      <vip v-if="isShowVip" @close="close1"></vip>
      <customer v-if="isShowCustomer" @close="close"></customer>
      <renewvip v-if="isShowRenewvip" @close="close1"></renewvip>
    </transition>

  </div>
</template>

<script>
import {
  setToken,
  setLoginStatus,
  getLoginStatus,
  removeToken,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
} from "@/utils/auth";
import login from '@/components/login/login.vue'
import verify from '@/components/verify/verify.vue'
import vip from '@/components/vip/vip.vue'
import renewvip from '@/components/renewvip/renewvip.vue'
import customer from '@/components/customer/customer.vue'
export default {
  name: "pHeader",
  components: { login, verify, vip, renewvip, customer },
  data() {
    return {
      dialogVisible: false,
      isRead: 0,
      code: "",
      isHome: true,
      curentPage: '',
      isShowLogin: false,
      isShowverify: false,
      isShowVip: false,
      isShowCustomer: false,
      isShowRenewvip: false
    };
  },
  computed: {
    isLogin: {
      get() {
        return this.$store.state.isLogin;
      },
      set(val) { },
    },
    avatar: {
      get() {
        return this.$store.state.avatar;
      },
      set(val) { },
    },
    nickname: {
      get() {
        return this.$store.state.nickname;
      },
      set(val) { },
    },
    vipname: {
      get() {
        return this.$store.state.vip;
      },
      set(val) { },
    },
    is_vip: {
      get() {
        return this.$store.state.is_vip;
      },
      set(val) { },
    },
    end_time: {
      get() {
        return this.$store.state.end_time;
      },
      set(val) { },
    },
  },
  watch: {
    '$route.path': { // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        this.curentPage = to;
        if (to == '/home' || to == '/login') {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    }
  },
  created: function () {
    let that = this;
    if (getLoginStatus() && getLoginStatus() == "true") {
      this.$store.commit("CHANGELOGINSTATUS", true);
      this.$store.commit("UPDATAINFO", getUserInfo());
    } else {
      this.$store.commit("CHANGELOGINSTATUS", false);
      removeUserInfo();
    }
    this.changeUser()
  },
  beforeDestroy: function () {
  },
  methods: {
    changeUser() {
      this.get(this.Api.userInfo, {}).then((res) => {
        setUserInfo(res);
        this.$store.commit("UPDATAINFO", res);
        if (res.has_mobile == false) {
          this.isShowverify = true
        } else {
          this.isShowverify = false
        }
      });
    },
    toHome() {
      this.$router.push("/home");
    },

    sureout() {
      this.axios.post(this.Api.logOut, {}).then((res) => {
        if (res.code == 1) {
          setLoginStatus("false");
          removeToken();
          removeUserInfo();
          this.$store.commit("CHANGELOGINSTATUS", false);
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          this.dialogVisible = false;
          if (this.curentPage == '/my') {
            this.$router.replace("/");
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    loginOut() {
      this.dialogVisible = true;
      return;
      this.$confirm("确定要退出登录吗?", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          setLoginStatus("false");
          removeToken();
          removeUserInfo();
          this.$store.commit("CHANGELOGINSTATUS", false);
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    showLogin() {
      // this.$router.push("/login");
      this.isShowLogin = true
    },
    close() {
      this.isShowLogin = false
      this.isShowverify = false
      this.isShowVip = false
      this.isShowCustomer = false
      this.isShowRenewvip = false
    },
    close1() {
      this.$store.commit("UPDATAINFO", getUserInfo());
      this.$forceUpdate()
      location.reload()
    },
    toMy(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="scss">
.webHeader {
  width: 100%;
  min-width: 1000px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(205, 205, 205, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .centeerpart {
    width: 1000px;
  }
}

.bgwhite {
  background: #fff;
}

.username {
  font-size: 16px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  margin-right: 10px;
}

.blackusername {
  color: #333333;
}

.logo {
  width: 32px;
  height: 32px;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
}

.navtitle {
  height: 36px;
  font-size: 24px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
  white-space: nowrap;
  cursor: pointer;
  height: 36px;
  font-size: 28px;
  font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
  font-weight: 400;
  color: #5886f7;
  line-height: 36px;
}
.navtitleimg {
  width: 173px;
  height: 24px;
  cursor: pointer;
}
.opratepart {
  margin-left: 236px;
}

.badge ::v-deep .el-badge__content.is-fixed.is-dot {
  top: 8px;
  right: 8px;
}

.btn {
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  width: 68px;
  height: 32px;
  border-radius: 19px 19px 19px 19px;
  border: 1px solid #ffffff;
  font-size: 16px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}

.bluebtn {
  border: 1px solid #5886f7;
  color: #5886f7;
}

.vip {
  width: 59px;
  height: 22px;
  background: #ebebeb;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  font-size: 13px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #666666;
  line-height: 22px;
  text-align: center;
  margin-right: 8px;
}

.is_vip {
  background: #ffd7a9;

  span {
    background-image: linear-gradient(180deg, #a35f38 0%, #733211 100%);
    color: transparent;
    -webkit-background-clip: text;
  }
}

.viptime {
  box-sizing: border-box;
  width: 204px;
  height: 62px;
  background: rgba(255, 215, 169, 0.2);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ffd7a9;
  margin: 14px;
  padding: 4px;
  white-space: nowrap;

  img {
    width: 40px;
    height: 40px;
  }

  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #93522e;
  line-height: 21px;
  .vipright {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }
  &-name {
    height: 24px;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #8b4925;
    line-height: 24px;
    margin-bottom: 2px;
  }
  &-desc {
    height: 20px;
    font-size: 13px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #93522e;
    line-height: 20px;
  }
}

.downicon {
  width: 12px;
  height: 8px;
  margin-left: 6px;
}

.dropicon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

::v-deep.el-dropdown-menu__item:focus,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
  // color: #eb3535;
  // background: #ffeded;
  white-space: nowrap;
}

// ::v-deep.el-dropdown-menu__item:focus .icon1,
// ::v-deep.el-dropdown-menu__item:not(.is-disabled):hover .icon1 {
//   content: url(@/static/common/myed.png);
// }

// ::v-deep.el-dropdown-menu__item:focus .icon3,
// ::v-deep.el-dropdown-menu__item:not(.is-disabled):hover .icon3 {
//   content: url(@/static/common/outed.png);
// }

::v-deep.el-dropdown-menu {
  width: 232px;
  min-width: 122px;
  right: 0;
}

// ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
//   border-color: #eb3535;
// }

// ::v-deep .el-checkbox__inner:hover {
//   border-color: #eb3535;
// }

// ::v-deep .el-button--primary {
//   background-color: #eb3535;
//   border-color: #eb3535;
// }

/* ::v-deep .el-button:focus,
.el-button:hover {
	color: unset;
	border-color: unset;
	background-color: unset;
} */
</style>
